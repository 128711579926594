<template>
  <div class="privacy-policy">
    <h1>Privacy Policy for Kuwait Riding Center</h1>
    <p><strong>Last Updated:</strong> 2025-01-01</p>

    <p>
      Thank you for using Kuwait Riding Center. This Privacy Policy explains how
      we collect, use, and protect your information when you use our App. By
      using the App, you agree to the terms of this Privacy Policy.
    </p>

    <section>
      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, email address, phone
          number, etc. (if provided by you).
        </li>
        <li>
          <strong>Device Information:</strong> IP address, device model,
          operating system, etc.
        </li>
        <li>
          <strong>Usage Data:</strong> App usage patterns, features accessed,
          etc.
        </li>
        <li>
          <strong>Location Data:</strong> If applicable, with your consent.
        </li>
      </ul>
    </section>

    <section>
      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and improve the App.</li>
        <li>Personalize your experience.</li>
        <li>Analyze usage trends and performance.</li>
        <li>Communicate with you about updates or support.</li>
      </ul>
    </section>

    <section>
      <h2>3. Data Sharing and Disclosure</h2>
      <p>
        We do not sell your personal information. However, we may share your
        information with:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third-party vendors who help us
          operate the App.
        </li>
        <li>
          <strong>Legal Requirements:</strong> When required by law or to
          protect our rights.
        </li>
      </ul>
    </section>

    <section>
      <h2>4. Your Rights and Choices</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of data collection or marketing communications.</li>
      </ul>
      <p>
        To exercise these rights, contact us at
        <a href="mailto:info@kuwaitrc.com">info@kuwaitrc.com</a>.
      </p>
    </section>

    <section>
      <h2>5. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your data,
        including encryption and secure servers.
      </p>
    </section>

    <section>
      <h2>6. Third-Party Services</h2>
      <p>
        Our App may use third-party services (e.g., Google Analytics, payment
        processors). These services have their own privacy policies, which we
        encourage you to review.
      </p>
    </section>

    <section>
      <h2>7. Children’s Privacy</h2>
      <p>
        Our App is not intended for children under 13. We do not knowingly
        collect personal information from children.
      </p>
    </section>

    <section>
      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new policy on this page.
      </p>
    </section>

    <section>
      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>
          <a href="mailto:info@kuwaitrc.com">info@kuwaitrc.com</a>
        </li>
        <li><strong>Address:</strong> Sabhan Industrial Area</li>
      </ul>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
  };
</script>

<style scoped>
  .privacy-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  h1,
  h2 {
    color: #333;
  }
  a {
    color: #007bff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
</style>
